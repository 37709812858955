import axios from "axios";
// let serverurl = "http://localhost/api";

let serverurl = "https://api.workflow.gzonetechph.com/";
// let serverurl = "https://workflowapi.greenzoneph.com/";
// let serverurl = "https://workflowapi.usiphil.com/";

// let serverurl = "http://61.245.28.186:14143/backend/api.workflow/";
// let serverurl = "http://122.54.107.118/backend/api.workflow/";
// package.json ::: "homepage": "/apps/fieldplus",


let key = "?key=PocketHR@20190208&type=web";
let key2 = "?key=12345ABCFleet";
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export function getData(method, param, discon) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        serverurl + method + key,
        {
          param,
        },
        {
          cancelToken: source.token,
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function loginUser(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function cancelRequest() {
  source.cancel("Operation canceled by the user.");
  source = CancelToken.source();
}
export const apiurl = serverurl;
export const serverImageMeter = serverurl + "assets/img/meter/";
export const loginurl = "https://login.pockethr.gzonetechph.com/";
export const serverProfile =
  "https://images.workflow.gzonetechph.com/pockethr/profilepic/";
export const serverImage =
  "https://images.workflow.gzonetechph.com/";



// SSL CLOUD
// export const apiurl = serverurl;
// export const serverImageMeter = serverurl + "assets/img/meter/";
// export const loginurl = "https://login.greenzoneph.com/";
// export const serverProfile =
//   "https://images.workflow.gzonetechph.com/pockethr/profilepic/";
// export const serverImage =
//   "https://images.workflow.gzonetechph.com/";



// DIRECT IP
// export const apiurl = serverurl;
// export const serverImageMeter = serverurl + "assets/img/meter/";
// export const loginurl = "http://61.245.28.186:14143/apps/local_login";
// export const serverProfile =
//   "http://images.workflow.gzonetechph.com/pockethr/profilepic/";
// export const serverImage =
//   "http://images.workflow.gzonetechph.com/";

