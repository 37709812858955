import React, { useEffect } from "react";
// import '../../../App';

import KebabMenuButton from './actionbutton';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../../css/css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
// import InitialTable from './initial_table/initial_table'
import Button from "@material-ui/core/Button";
import TableChartIcon from "@material-ui/icons/TableChart";
import TextField from "@material-ui/core/TextField";
import { Redirect, Link as NewLink } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ViewIcon from "@material-ui/icons/Visibility";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { DropzoneArea } from "material-ui-dropzone";
import Paper from "@material-ui/core/Paper";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import { getData, getDataDiscon } from "../../api/api";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";

import { useHistory } from 'react-router-dom';

const styles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    maxWidth: window.innerWidth * 0.8,
  },
  tableCell: {
    width: 150,
    height: 36,
    backgroundColor: "#fff",
    borderColor: "#b2bec3",
    borderWidth: 0.5,
    marginRight: 5,
  },
}));

function InputData({ edit, refreshRemove, val, onChangeText }) {
  const [inputValue, setInputValue] = React.useState(val.column_value);

  React.useEffect(() => {
    setInputValue(val.column_value);
  }, [refreshRemove]);

  return (
    <input
      // disabled={!edit ? true : false}
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
        onChangeText(e);
      }}
    />
  );
}
function SelectData({
  refreshRemove,
  hearderFields,
  classes,
  onSelect,
  row,
  index,
  edit,
}) {
  const [inputValue, setInputValue] = React.useState(row.header);

  React.useEffect(() => {
    setInputValue(row.header);
  }, [refreshRemove]);
  return (
    <select
      disabled={!edit ? true : false}
      required
      onChange={(e) => {
        setInputValue(e.target.value);
        onSelect(e, index);
      }}
      className={classes.tableCell}
      value={inputValue}
    >
      <option value="" disabled selected>
        Select
      </option>
      <option value="excluded">--Excluded--</option>
      {hearderFields.map((val) => {
        return (
          <option value={val.category_field}>{val.category_details}</option>
        );
      })}
    </select>
  );
}
function Index({
  upload_data,
  refresh_table_template,
  template_status,
  dynamicHeader,
  branch_id,
  company_id,
  ba,
  jo_type,
  cancelTemplate,
  date_start_val,
  file_name,
  update_collectible
}) {
  const history = useHistory();
  const home_reducer = useSelector((state) => state.home_reducer);
  const classes = styles();
  const dispatch = useDispatch();

  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  const [state, setState] = React.useState({
    selected_company: "",
    selected_branch: "",
    branches: [],
    jo_type: [],
    date_start_val: new Date(),
    selected_jo_type: "",
    header: [],
    hearderFields: [],
    columnAdd: [],
    refreshRemove: false,
    edit: false,
    uploadName: [],
  });
  let id = 0;
  function createData(name, calories, fat, carbs, protein, x, y) {
    id += 1;
    return { id, name, calories, fat, carbs, protein, x, y };
  }


  const getUploadFile = () => {
    dispatch_data("LoadingIndex", true);

    getData("billing/getBillingGenerated", { user_id: localStorage.getItem("u") }).then(
      (res) => {
        setState((prev) => ({
          ...prev,
          uploadName: res,
        }));

        let total_amount = 0;
        res.map((val,index) => {
          if(val.bill_status == 'Pending' && val.bill_delete_id == 0)
            total_amount += parseFloat(val.bill_amount);
        })
        update_collectible(total_amount)

        dispatch_data("LoadingIndex", false);
      }
    );
  };
  React.useEffect(() => {
    getUploadFile();
  }, []);
  useEffect(() => {
    getUploadFile();

  }, [refresh_table_template]);
  
  
  const UpdateStatus = (action,billing_filter) => {
    billing_filter['user_id'] = localStorage.getItem("u");

    if(action == 'View'){
      localStorage.setItem("billing_data", JSON.stringify(billing_filter));
      
      history.push('/reports');
    }else if(action == 'Cancel'){
      billing_filter['status'] = 'Cancelled';
      getData("billing/UpdateBillingStatus", billing_filter).then(
        (res) => {
          
          cancelTemplate();
        }
      );
    }else if(action == 'Paid'){
      billing_filter['status'] = 'Paid';
      getData("billing/UpdateBillingStatus", billing_filter).then(
        (res) => {
          
          cancelTemplate();
        }
      );
    }

  };
  
  return (
    <Grid item xs={12} md={12}>
      <Paper
        className={classes.root}
        variant={"outlined"}
        style={{ padding: 10, minHeighhet: 230, width: "100%" }}
      >
       
          <>
            <TableContainer style={{ height: '80vh', overflow: "auto" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: "rgba(6,86,147)",
                        color: "#fff",
                      }}
                    >
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgba(6,86,147)",
                        color: "#fff",
                      }}
                    >
                      ID
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgba(6,86,147)",
                        color: "#fff",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgba(6,86,147)",
                        color: "#fff",
                      }}
                      colSpan={"5"}
                    >
                      Branch
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgba(6,86,147)",
                        color: "#fff",
                      }}
                    >
                      BA
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgba(6,86,147)",
                        color: "#fff",
                      }}
                    >
                      Job Type
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgba(6,86,147)",
                        color: "#fff",
                      }}
                    >
                      Total Amount
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgba(6,86,147)",
                        color: "#fff",
                      }}
                    >
                      Date Generated
                    </TableCell>
                   
                    <TableCell
                      style={{
                        backgroundColor: "rgba(6,86,147)",
                        color: "#fff",
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.uploadName.map((val, index) => {
                     const startdate = moment(new Date(val.bill_date_start)).format('MMMM DD, YYYY');
                     const enddate = moment(new Date(val.bill_date_end)).format('MMMM DD, YYYY');
                     const created = moment(new Date(val.bill_date_created)).format('MMMM DD, YYYY h:mm A');
                   
                     let billing_filter ={
                      'accom_bill_id':val.accom_bill_id,
                      'bill_jo_type':val.bill_jo_type,
                      'bill_ba':val.bill_ba,
                      'company_id':val.company_id,
                      'branch_id':val.branch_id,
                      'bill_date_start':val.bill_date_start,
                      'bill_date_end':val.bill_date_end,
                      'bill_pdf':val.bill_pdf
                    };

                    return (
                      <TableRow>
                        <TableCell>
                            <ViewIcon onClick={()=> {
                              localStorage.setItem("billing_data", JSON.stringify(billing_filter));
                              
                              history.push('/reports');
                              }}>

                            </ViewIcon>

                            {val.bill_status == 'Pending' && <KebabMenuButton UpdateStatus={(action) => UpdateStatus(action,billing_filter)}/>}
                          </TableCell>
                        <TableCell>{val.accom_bill_id}</TableCell>
                        <TableCell>{startdate +" - "+ enddate}</TableCell>
                        <TableCell colSpan={"5"}>{val.branch_name}</TableCell>
                        <TableCell>{val.bill_ba}</TableCell>
                        <TableCell>{val.bill_jo_type}</TableCell>
                        <TableCell>{parseFloat(val.bill_amount).toLocaleString()}</TableCell>
                        <TableCell>{created}</TableCell>
                        <TableCell style={
                          val.bill_status === 'Pending' ? 
                          { backgroundColor: 'gray', color: 'white', fontWeight: 'bold', textAlign: 'center' } :
                          val.bill_status === 'Cancelled' ?
                          { backgroundColor: 'red', color: 'white', fontWeight: 'bold', textAlign: 'center' } :
                          { backgroundColor: '#58B19F', color: 'white', fontWeight: 'bold', textAlign: 'center' }}>{
                          val.bill_status}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>

      </Paper>
    </Grid>
  );
}

export default Index;
