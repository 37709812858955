import React, { useEffect } from "react";
// import '../../../App';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../../css/css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
// import InitialTable from './initial_table/initial_table'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import { IconButton, TableHead, TableRow,Table,TableCell,TableBody } from "@material-ui/core";
import { getData } from "../../api/api";
function Index({ amount }) {
  const home_reducer = useSelector((state) => state.home_reducer);
  const classes = useStyles();
  const dispatch = useDispatch();

  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  const [state, setState] = React.useState({
    selected_company: "",
    selected_branch: "",
    branches: [],
    jo_type: [],
    date_start_val: new Date(),
    date_end_val: new Date(),
    selected_jo_type: "",
    dynamic_header_data: [],
    business_area: [],
    selected_ba: "",
    selected_dynamic_header_data: [],
  });
  
  
  
  return (
    <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
    <Card className={classes.root} style={{ backgroundColor: '#58B19F' }}>
            <CardContent>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems:'center',
                    marginTop: 10,
                    color: 'white'
                  }}
                >
                  <Typography>Total Collectible Amount</Typography>

                  <Typography style={{ fontWeight: '800', fontSize: '30px'}}>
                  Php {amount.toLocaleString()}
                  </Typography>
                </div>
            </CardContent>
          </Card>

    </Grid>
   
    </Grid>

   
  );
}

export default Index;
