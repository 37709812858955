import React, { PureComponent, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import MarkerClusterer from "@google/markerclusterer";
var newMap = [];
var markerCluster = [];
let flightPath
let infowindowPoly
let bermudaTriangle
const getMapOptions = (maps, styleMap) => {
  return {
    streetViewControl: true,
    scaleControl: false,
    fullscreenControl: false,
    zoomControl: false,
    styles: [],
    gestureHandling: "greedy",
    disableDoubleClickZoom: false,
    mapTypeControl: false,

    mapTypeId: styleMap === "HYBRID" ? maps.MapTypeId.HYBRID :
      styleMap === "SATELLITE" ? maps.MapTypeId.SATELLITE :
        styleMap === "ROAD MAP" ? maps.MapTypeId.ROADMAP : maps.MapTypeId.HYBRID
    ,
    streetViewControlOptions: {
      // position: maps.ControlPosition.BOTTOM_LEFT,
    },
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      // position: maps.ControlPosition.BOTTOM_LEFT,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },

    zoomControl: false,
    clickableIcons: false,
  };
};
var stateGeoJson = [{
  "type": "FeatureCollection", "features": [{
    "type": "Feature", "properties": { "GEO_ID": "0400000US48", "STATE": "48", "NAME": "Texas" }, "geometry": {
      "type": "MultiPolygon", "coordinates": [
        // simplified texas
        [[[-106.6552734375, 32.0639555946604], [-103.0078125, 32.0639555946604], [-103.0078125, 36.491973470593685], [-99.9755859375, 36.491973470593685], [-99.9755859375, 34.59704151614417], [-99.0087890625, 34.23451236236984], [-97.822265625, 33.90689555128866], [-96.240234375, 33.687781758439364], [-95.09765625, 33.870415550941836], [-94.0869140625, 33.43144133557529], [-94.0869140625, 31.989441837922904], [-93.3837890625, 31.05293398570514], [-93.9990234375, 29.611670115197377], [-97.1630859375, 27.916766641249065], [-97.470703125, 26.745610382199025], [-97.03125, 26.03704188651584], [-97.5146484375, 25.878994400196202], [-99.140625, 26.509904531413927], [-99.5361328125, 27.68352808378776], [-100.3271484375, 28.34306490482549], [-100.72265625, 29.19053283229458], [-101.42578125, 29.726222319395507], [-102.6123046875, 29.76437737516313], [-103.095703125, 28.998531814051795], [-104.6337890625, 29.649868677972304], [-104.8974609375, 30.524413269923986], [-106.6552734375, 32.0639555946604]],
        // san antonio
        [[-98.68846, 29.21083],
        [-98.682015, 29.20863],
        [-98.63851, 29.193774],
        [-98.625318, 29.189273],
        [-98.590587, 29.177251],
        [-98.585943, 29.175655],
        [-98.58573, 29.175582],
        [-98.557348, 29.165861],
        [-98.547608, 29.162526],
        [-98.526902, 29.155435],
        [-98.52462, 29.154688],
        [-98.524215, 29.154515],
        [-98.497539, 29.14541],
        [-98.484266, 29.140821],
        [-98.484041, 29.140744],
        [-98.465819, 29.134476],
        [-98.441548, 29.126127],
        [-98.441456, 29.126092],
        [-98.431298, 29.122507],
        [-98.407336, 29.114435],
        [-98.397585, 29.125244],
        [-98.372654, 29.1555],
        [-98.330197, 29.206269],
        [-98.327196, 29.209868],
        [-98.304559, 29.237003],
        [-98.303019, 29.23885],
        [-98.300464, 29.24191],
        [-98.295924, 29.247353],
        [-98.29568, 29.250546],
        [-98.29246, 29.251235],
        [-98.28702, 29.257626],
        [-98.283214, 29.26233],
        [-98.255615, 29.296176],
        [-98.249288, 29.303938],
        [-98.237988, 29.317737],
        [-98.20469, 29.358113],
        [-98.204587, 29.358236],
        [-98.188143, 29.378514],
        [-98.138033, 29.439391],
        [-98.137187, 29.440428],
        [-98.134171, 29.441751],
        [-98.129128, 29.444268],
        [-98.128681, 29.445363],
        [-98.12512, 29.446405],
        [-98.120922, 29.446476],
        [-98.121075, 29.449048],
        [-98.124107, 29.453224],
        [-98.126771, 29.454736],
        [-98.126864, 29.457223],
        [-98.125594, 29.45824],
        [-98.123575, 29.457675],
        [-98.120243, 29.457468],
        [-98.117774, 29.46023],
        [-98.117033, 29.461925],
        [-98.116902, 29.463884],
        [-98.118722, 29.469263],
        [-98.120577, 29.470841],
        [-98.121138, 29.473542],
        [-98.122276, 29.473019],
        [-98.124087, 29.474003],
        [-98.12488, 29.475642],
        [-98.123057, 29.47638],
        [-98.122322, 29.477646],
        [-98.12514, 29.480196],
        [-98.125881, 29.481648],
        [-98.128986, 29.482453],
        [-98.131106, 29.481381],
        [-98.131861, 29.480127],
        [-98.134125, 29.478923],
        [-98.136269, 29.479265],
        [-98.137092, 29.481191],
        [-98.136802, 29.482495],
        [-98.135481, 29.483089],
        [-98.13565, 29.484216],
        [-98.137172, 29.484234],
        [-98.139449, 29.482485],
        [-98.14058, 29.484152],
        [-98.139491, 29.485549],
        [-98.141786, 29.486485],
        [-98.143622, 29.485743],
        [-98.142793, 29.484027],
        [-98.144466, 29.484231],
        [-98.143982, 29.482492],
        [-98.142467, 29.481611],
        [-98.142959, 29.479741],
        [-98.144229, 29.47813],
        [-98.150015, 29.475667],
        [-98.150989, 29.476278],
        [-98.150156, 29.479752],
        [-98.150786, 29.48058],
        [-98.152409, 29.478616],
        [-98.155754, 29.477809],
        [-98.159124, 29.478651],
        [-98.1613, 29.477716],
        [-98.164638, 29.478091],
        [-98.166581, 29.479439],
        [-98.166832, 29.482036],
        [-98.168767, 29.487092],
        [-98.166943, 29.489622],
        [-98.168288, 29.491169],
        [-98.168344, 29.496153],
        [-98.169381, 29.497528],
        [-98.171507, 29.496747],
        [-98.172583, 29.494503],
        [-98.173764, 29.496627],
        [-98.176336, 29.496688],
        [-98.178625, 29.497662],
        [-98.181543, 29.497392],
        [-98.183807, 29.495747],
        [-98.184914, 29.493393],
        [-98.184323, 29.492716],
        [-98.180456, 29.492782],
        [-98.179092, 29.490911],
        [-98.179651, 29.489569],
        [-98.177608, 29.487176],
        [-98.176923, 29.485399],
        [-98.178017, 29.484442],
        [-98.18311, 29.484899],
        [-98.187592, 29.488212],
        [-98.188694, 29.491363],
        [-98.189964, 29.492843],
        [-98.18975, 29.49665],
        [-98.188285, 29.499412],
        [-98.186324, 29.5012],
        [-98.1899, 29.505719],
        [-98.190148, 29.509328],
        [-98.195356, 29.51111],
        [-98.195891, 29.510103],
        [-98.198573, 29.509365],
        [-98.200237, 29.511445],
        [-98.19969, 29.513596],
        [-98.19701, 29.514757],
        [-98.196923, 29.515731],
        [-98.198571, 29.517326],
        [-98.201885, 29.516869],
        [-98.202533, 29.517705],
        [-98.201332, 29.519048],
        [-98.204779, 29.52215],
        [-98.208403, 29.522529],
        [-98.209975, 29.523365],
        [-98.214272, 29.522963],
        [-98.216354, 29.524349],
        [-98.218467, 29.523369],
        [-98.219147, 29.52393],
        [-98.219368, 29.528533],
        [-98.221078, 29.531246],
        [-98.222182, 29.534811],
        [-98.222146, 29.537986],
        [-98.224399, 29.540312],
        [-98.229439, 29.54195],
        [-98.229455, 29.543401],
        [-98.224413, 29.543463],
        [-98.22168, 29.544381],
        [-98.221332, 29.544362],
        [-98.220965, 29.545248],
        [-98.22094, 29.546194],
        [-98.221589, 29.547932],
        [-98.222653, 29.549032],
        [-98.224912, 29.549681],
        [-98.225432, 29.549718],
        [-98.226352, 29.549642],
        [-98.226666, 29.54941],
        [-98.227105, 29.547141],
        [-98.229565, 29.54669],
        [-98.230589, 29.548108],
        [-98.230695, 29.550837],
        [-98.233899, 29.554893],
        [-98.239204, 29.556575],
        [-98.242992, 29.557058],
        [-98.246402, 29.556],
        [-98.248931, 29.554596],
        [-98.248885, 29.551521],
        [-98.250755, 29.550017],
        [-98.253796, 29.550311],
        [-98.255361, 29.552332],
        [-98.254636, 29.554009],
        [-98.255605, 29.554917],
        [-98.25853, 29.554935],
        [-98.259621, 29.552449],
        [-98.260075, 29.549621],
        [-98.25937, 29.547487],
        [-98.257806, 29.545714],
        [-98.253881, 29.544211],
        [-98.253021, 29.54321],
        [-98.253882, 29.540982],
        [-98.255377, 29.540491],
        [-98.259746, 29.541265],
        [-98.260658, 29.542349],
        [-98.264818, 29.543949],
        [-98.267432, 29.544178],
        [-98.270389, 29.54573],
        [-98.27084, 29.548822],
        [-98.272924, 29.550878],
        [-98.272973, 29.550913],
        [-98.273036, 29.550913],
        [-98.27333, 29.551006],
        [-98.279069, 29.551141],
        [-98.284329, 29.553365],
        [-98.287782, 29.559373],
        [-98.294766, 29.561531],
        [-98.298524, 29.561141],
        [-98.298328, 29.56322],
        [-98.29985, 29.566868],
        [-98.2999, 29.566918],
        [-98.301539, 29.568342],
        [-98.301882, 29.568759],
        [-98.301938, 29.568828],
        [-98.302308, 29.569476],
        [-98.302903, 29.574654],
        [-98.305066, 29.576303],
        [-98.306073, 29.579114],
        [-98.303927, 29.582426],
        [-98.304431, 29.58522],
        [-98.310636, 29.589182],
        [-98.312058, 29.590854],
        [-98.310928, 29.594473],
        [-98.31095, 29.59456],
        [-98.310837, 29.598332],
        [-98.312033, 29.601543],
        [-98.313292, 29.602236],
        [-98.31348, 29.602263],
        [-98.313494, 29.602279],
        [-98.314806, 29.602844],
        [-98.320456, 29.601939],
        [-98.326234, 29.604079],
        [-98.328651, 29.608233],
        [-98.328736, 29.610537],
        [-98.335328, 29.611301],
        [-98.339187, 29.6131],
        [-98.34234, 29.617193],
        [-98.345121, 29.618092],
        [-98.345673, 29.619742],
        [-98.342715, 29.62195],
        [-98.342271, 29.62556],
        [-98.340861, 29.627541],
        [-98.340604, 29.630237],
        [-98.343052, 29.634768],
        [-98.343351, 29.635138],
        [-98.343401, 29.63532],
        [-98.343672, 29.635611],
        [-98.343874, 29.635952],
        [-98.344178, 29.63638],
        [-98.346072, 29.639313],
        [-98.347618, 29.645018],
        [-98.3434, 29.650993],
        [-98.342733, 29.653442],
        [-98.345108, 29.655058],
        [-98.356629, 29.658648],
        [-98.359781, 29.662987],
        [-98.360802, 29.665226],
        [-98.363764, 29.666732],
        [-98.370994, 29.664303],
        [-98.377117, 29.662728],
        [-98.378906, 29.662949],
        [-98.380541, 29.668434],
        [-98.380854, 29.671906],
        [-98.381646, 29.674085],
        [-98.382419, 29.674429],
        [-98.382452, 29.674443],
        [-98.382566, 29.67446],
        [-98.384341, 29.676534],
        [-98.384113, 29.678591],
        [-98.381781, 29.680455],
        [-98.377325, 29.682918],
        [-98.376315, 29.687055],
        [-98.376711, 29.689041],
        [-98.380827, 29.696283],
        [-98.380523, 29.699539],
        [-98.380965, 29.701485],
        [-98.380161, 29.703929],
        [-98.375874, 29.706215],
        [-98.374595, 29.706273],
        [-98.371274, 29.705],
        [-98.368087, 29.705647],
        [-98.362327, 29.70597],
        [-98.35722, 29.703912],
        [-98.354557, 29.703864],
        [-98.35128, 29.704867],
        [-98.347273, 29.706777],
        [-98.344689, 29.707239],
        [-98.342868, 29.708384],
        [-98.34135, 29.70965],
        [-98.340367, 29.711795],
        [-98.340361, 29.715819],
        [-98.338724, 29.71705],
        [-98.338006, 29.719146],
        [-98.338618, 29.721786],
        [-98.341505, 29.723337],
        [-98.346283, 29.726703],
        [-98.349208, 29.727511],
        [-98.350208, 29.728748],
        [-98.352406, 29.731884],
        [-98.352205, 29.73415],
        [-98.355817, 29.734072],
        [-98.35672, 29.732885],
        [-98.356825, 29.732614],
        [-98.35692, 29.732452],
        [-98.359382, 29.729037],
        [-98.364366, 29.724332],
        [-98.369828, 29.720693],
        [-98.37521, 29.719975],
        [-98.375644, 29.722654],
        [-98.374999, 29.724619],
        [-98.3735, 29.726022],
        [-98.373248, 29.729186],
        [-98.373729, 29.730594],
        [-98.376967, 29.732618],
        [-98.384353, 29.735141],
        [-98.388262, 29.733943],
        [-98.390972, 29.73648],
        [-98.393632, 29.736481],
        [-98.399172, 29.738111],
        [-98.398262, 29.742842],
        [-98.402087, 29.746859],
        [-98.403272, 29.748824],
        [-98.407786, 29.748599],
        [-98.410834, 29.747426],
        [-98.416064, 29.746549],
        [-98.418984, 29.744937],
        [-98.420673, 29.743529],
        [-98.421765, 29.740377],
        [-98.421803, 29.738842],
        [-98.42048, 29.733814],
        [-98.417851, 29.733318],
        [-98.416887, 29.731695],
        [-98.418627, 29.729489],
        [-98.421048, 29.728879],
        [-98.425908, 29.727015],
        [-98.429955, 29.723588],
        [-98.426842, 29.71769],
        [-98.430926, 29.715754],
        [-98.438955, 29.715765],
        [-98.441217, 29.716627],
        [-98.443852, 29.71965],
        [-98.444704, 29.722466],
        [-98.444606, 29.726149],
        [-98.442918, 29.730471],
        [-98.442681, 29.732286],
        [-98.443671, 29.734646],
        [-98.446778, 29.735983],
        [-98.451203, 29.734403],
        [-98.452479, 29.733207],
        [-98.452595, 29.732802],
        [-98.457494, 29.731564],
        [-98.459366, 29.731773],
        [-98.462127, 29.733505],
        [-98.464959, 29.738115],
        [-98.465974, 29.738538],
        [-98.468471, 29.737861],
        [-98.471647, 29.736298],
        [-98.473972, 29.734366],
        [-98.476991, 29.733436],
        [-98.479778, 29.73508],
        [-98.485332, 29.735832],
        [-98.487935, 29.735897],
        [-98.48975, 29.73534],
        [-98.492354, 29.735934],
        [-98.493023, 29.737034],
        [-98.496957, 29.73849],
        [-98.50115, 29.738241],
        [-98.503974, 29.738644],
        [-98.507188, 29.739795],
        [-98.509728, 29.741259],
        [-98.514491, 29.742856],
        [-98.516226, 29.743083],
        [-98.520241, 29.744905],
        [-98.523374, 29.745242],
        [-98.526086, 29.743416],
        [-98.525381, 29.740786],
        [-98.526781, 29.740445],
        [-98.528192, 29.742228],
        [-98.528286, 29.743659],
        [-98.533959, 29.74679],
        [-98.533625, 29.750505],
        [-98.534015, 29.752353],
        [-98.536575, 29.752843],
        [-98.538996, 29.751903],
        [-98.543718, 29.752052],
        [-98.547306, 29.753615],
        [-98.547867, 29.754391],
        [-98.546882, 29.757301],
        [-98.548067, 29.759997],
        [-98.550489, 29.760713],
        [-98.553017, 29.76019],
        [-98.554997, 29.758633],
        [-98.555117, 29.755255],
        [-98.558359, 29.751975],
        [-98.559304, 29.751482],
        [-98.562614, 29.751476],
        [-98.562967, 29.752153],
        [-98.565545, 29.752659],
        [-98.566838, 29.751922],
        [-98.569321, 29.748274],
        [-98.572107, 29.749715],
        [-98.574567, 29.750419],
        [-98.575216, 29.750001],
        [-98.576407, 29.747107],
        [-98.579294, 29.745478],
        [-98.578928, 29.743597],
        [-98.581475, 29.742782],
        [-98.582849, 29.741137],
        [-98.586682, 29.742451],
        [-98.587496, 29.746418],
        [-98.588493, 29.747595],
        [-98.591412, 29.747215],
        [-98.593113, 29.745679],
        [-98.596972, 29.747362],
        [-98.600406, 29.74998],
        [-98.603404, 29.749522],
        [-98.604739, 29.747255],
        [-98.60129, 29.744874],
        [-98.599997, 29.743031],
        [-98.600469, 29.741265],
        [-98.603381, 29.739933],
        [-98.607473, 29.740455],
        [-98.611599, 29.740063],
        [-98.614198, 29.737591],
        [-98.617092, 29.739098],
        [-98.616924, 29.742916],
        [-98.619232, 29.744142],
        [-98.623339, 29.743933],
        [-98.628756, 29.744146],
        [-98.63288, 29.741936],
        [-98.640969, 29.740228],
        [-98.644736, 29.741184],
        [-98.646119, 29.744587],
        [-98.64613, 29.744736],
        [-98.646098, 29.745071],
        [-98.646124, 29.745181],
        [-98.645551, 29.747977],
        [-98.64776, 29.749809],
        [-98.649778, 29.7535],
        [-98.652501, 29.75354],
        [-98.653347, 29.75135],
        [-98.653051, 29.748831],
        [-98.655989, 29.747775],
        [-98.658515, 29.749207],
        [-98.658826, 29.749239],
        [-98.6593, 29.749211],
        [-98.661108, 29.7493],
        [-98.663738, 29.746275],
        [-98.663391, 29.745131],
        [-98.660567, 29.743722],
        [-98.659704, 29.742137],
        [-98.663241, 29.741027],
        [-98.668479, 29.743476],
        [-98.670875, 29.743272],
        [-98.673832, 29.742117],
        [-98.674501, 29.738805],
        [-98.675762, 29.737414],
        [-98.68141, 29.738305],
        [-98.682935, 29.739351],
        [-98.687549, 29.73872],
        [-98.692725, 29.735208],
        [-98.699994, 29.737441],
        [-98.701135, 29.735719],
        [-98.699888, 29.732706],
        [-98.701624, 29.732343],
        [-98.701892, 29.732388],
        [-98.702695, 29.732655],
        [-98.703109, 29.732883],
        [-98.703602, 29.733023],
        [-98.707789, 29.732372],
        [-98.710692, 29.730851],
        [-98.712852, 29.728209],
        [-98.715592, 29.72582],
        [-98.717234, 29.725275],
        [-98.719691, 29.726762],
        [-98.72302, 29.729565],
        [-98.726063, 29.727748],
        [-98.732525, 29.729534],
        [-98.73383, 29.729539],
        [-98.733709, 29.7279],
        [-98.730532, 29.725667],
        [-98.728797, 29.722587],
        [-98.733117, 29.721566],
        [-98.735403, 29.719633],
        [-98.73777, 29.716747],
        [-98.743771, 29.716359],
        [-98.744659, 29.718427],
        [-98.748051, 29.720555],
        [-98.750359, 29.721016],
        [-98.751543, 29.720257],
        [-98.752918, 29.721094],
        [-98.757052, 29.722026],
        [-98.75946, 29.722148],
        [-98.762252, 29.722011],
        [-98.766665, 29.719554],
        [-98.767755, 29.720748],
        [-98.770843, 29.721365],
        [-98.771518, 29.720458],
        [-98.775124, 29.719689],
        [-98.778782, 29.720167],
        [-98.802771, 29.694835],
        [-98.806552, 29.690709],
        [-98.806415, 29.675817],
        [-98.805934, 29.633678],
        [-98.805935, 29.633273],
        [-98.805941, 29.631567],
        [-98.805848, 29.580936],
        [-98.805799, 29.572047],
        [-98.805743, 29.561199],
        [-98.805653, 29.540013],
        [-98.805578, 29.522711],
        [-98.805341, 29.483027],
        [-98.805173, 29.463057],
        [-98.805092, 29.447384],
        [-98.805002, 29.429509],
        [-98.804944, 29.403889],
        [-98.804752, 29.365679],
        [-98.804751, 29.365411],
        [-98.804622, 29.331826],
        [-98.804592, 29.323344],
        [-98.804878, 29.313552],
        [-98.804898, 29.313209],
        [-98.804926, 29.312923],
        [-98.804868, 29.303847],
        [-98.804766, 29.278959],
        [-98.804758, 29.250381],
        [-98.804756, 29.249003],
        [-98.804756, 29.248586],
        [-98.799475, 29.248702],
        [-98.779769, 29.241959],
        [-98.775941, 29.240645],
        [-98.734966, 29.22671],
        [-98.711643, 29.218746],
        [-98.711339, 29.218643],
        [-98.711315, 29.218611],
        [-98.711234, 29.218607],
        [-98.68846, 29.21083]]]
      ]
    }
  },
  ]
},
{
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            841.0995483398436,
            13.915406111243858
          ],
          [
            841.0782623291014,
            13.877079548760937
          ],
          [
            841.0885620117188,
            13.846413736927795
          ],
          [
            841.1088180541992,
            13.87341319753682
          ],
          [
            841.1174011230469,
            13.90307564854344
          ],
          [
            841.0992050170898,
            13.917072339575775
          ]
        ]
      }
    }
  ]
}];
const Map = forwardRef((props, ref) => {
  const {
    mapOption,
    location,refreshMap
  } = props



  // function Map({ trackAccom, isOpen, onToggleOpen, refresh,clearCLuster,playButton,mapOption,clearTimeout,fieldmanDeatilsOpen}) {
  const map_reducer = useSelector((state) => state.map_reducer);
  const mapRef2 = React.useRef(false);

  // const [mapOption, setmapOption] = React.useState({
  //   zoom: 12,
  //   lat: 13.7565,
  //   lng: 121.0583,
  // });
  const [state, setState] = React.useState({
    new_marker: [],
    createPolyline:true,
    pathCoordinates:[],
    refresh:false,
    createData:[]
  });

  const mapRef = React.useRef();

  const getMapBounds = (map, maps, trackAccom) => {
    const bounds = new maps.LatLngBounds();

    trackAccom.forEach((place) => {
      bounds.extend(new maps.LatLng(
        place.lat,
        place.lng,
      ));
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };
  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 20),
    y: -height,
  });
  const setGoogleMapRef = (map, maps) => {
    console.log(state.pathCoordinates)
    mapRef.current = { map, maps };
    let googleRef = maps;
    flightPath = new googleRef.Polyline({
      path: state.pathCoordinates,
      geodesic: true,
      strokeColor: "#FFC312",
      strokeOpacity: 1.0,
      strokeWeight: 2.4,
      icons: [
        {
          // https://developers.google.com/maps/documentation/javascript/examples/overlay-symbol-custom
          icon: {
            path: "M -2,0 0,-2 2,0 0,2 z",
            strokeColor: "#FFC312",
            fillColor: "#FFC312",
            fillOpacity: 1,
          },
          offset: "0",
          repeat: "20px",
        },
      ],
    });

    flightPath.setMap(map);
    //Your code
    // let point = new window.google.maps.Data.Feature({
    //   geometry: new window.google.maps.Data.Point({lat:13.7963, lng:120.9762}),
    // });
    
    // let geoJson = "";
    // point.toGeoJson(function (json) {
    //   geoJson = (json)
    //   console.log(geoJson);
    // });

    // let stateLayer = new window.google.maps.Data();
    // let countyLayer = new window.google.maps.Data();
    // stateGeoJson.map((val)=>{
    //   stateLayer.addGeoJson(val);
    //   countyLayer.loadGeoJson('https://api.myjson.com/bins/3inb5');
    //   stateLayer.setStyle({
    //     fillColor: 'red',
    //     strokeColor: '#000',
    //     strokeOpacity: 1,
    //     strokeWeight: 1
    //   });
  
    //   countyLayer.setStyle({
    //     fillColor: 'red',  // make red so I can see that it is working
    //     strokeColor: 'black',
    //     strokeOpacity: 1,
    //     strokeWeight: 1
    //   });
    //   stateLayer.setMap(map);
    //   countyLayer.setMap(map);
    // })
   
    // let triangleCoords = [[
    //   { lat: 25.774, lng: -80.19 },
    //   { lat: 18.466, lng: -66.118 },
    //   { lat: 32.321, lng: -64.757 },
    // ]];
    // // triangleCoords.push(state.pathCoordinates)
    // console.log(triangleCoords)
    // triangleCoords.map((val)=>{
    //    bermudaTriangle = new window.google.maps.Polygon({
    //     paths: triangleCoords,
    //     strokeColor: "#FF0000",
    //     strokeOpacity: 0.8,
    //     strokeWeight: 3,
    //     fillColor: "#FF0000",
    //     fillOpacity: 0.35,
    //   });
    
    //   bermudaTriangle.setMap(map);
    // })

    newMap =
    location &&
    location.map((location, index) => {
      
      let image = require("../../../../assets/map image/destination.png");
      let icon = {
        url: image,
        scaledSize: new window.google.maps.Size(40, 40),
      };
      location.lat = parseFloat(location.lat)
      location.lng = parseFloat(location.lng)

      let marker = new googleRef.Marker({
        position: location,
        icon: icon,
        label: { color: '#000', fontWeight: 'bold', fontSize: '14px', text: String(index+1),className: 'marker-position',}
      });
      return marker;
    });
   
    markerCluster = new MarkerClusterer(map, newMap, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      gridSize: 10,
      minimumClusterSize: 2,
      maxZoom: 13,
    });

  };
 

  React.useEffect(() => {
    
    let mounted = true;
    const script = document.createElement("script");
    script.src =
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
    script.async = true;
    document.body.appendChild(script);

    return () => (mounted = false);
  }, []);

  React.useEffect(() => {
    if (mapRef.current) {
        markerCluster.clearMarkers();
        flightPath.setMap(null)
        const { map, maps } = mapRef.current;
        setGoogleMapRef(map, maps);
    }
}, [state.refresh,refreshMap]);




  return (
    <div style={{ height: "100vh", width: "100%", position: "absolute" }}>

      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB04YACNd6OwYwtU8eR4t-eeqXDe7jdX_A" }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => setGoogleMapRef(map, maps)}
        center={{ lat: mapOption.lat, lng: mapOption.lng }}
        zoom={mapOption.zoom}
      //   onClick={ev => {
      //     if (state.createPolyline) {
      //         flightPath.setMap(null);
      //         state.pathCoordinates.push({ lat: ev.lat, lng: ev.lng })
      //         setState(prev => ({ ...prev, refresh: !state.refresh }))
      //     }

      // }}
        options={(maps) => getMapOptions(maps, localStorage.getItem('MapOption'))}
      ></GoogleMapReact>
    </div>
  );
}
)
export default React.memo(Map);
