import React, { useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const KebabMenuButton = ({ UpdateStatus }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleMenuItemClick = (action) => {
    if(action == 'View'){
      UpdateStatus(action);
      toggleMenu();
    }else{
      setSelectedOption(action);
      setIsConfirmationDialogOpen(true);
    }
  };


  const handleConfirm = () => {
    console.log(`Confirmed action: ${selectedOption}`);

    toggleMenu();
    setIsConfirmationDialogOpen(false);

    UpdateStatus(selectedOption);
  };

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const menuOptionsStyle = {
    position: 'absolute',
    top: 'auto',
    bottom: 'auto',
    left: 0,
    width: '120px',
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #ccc',
    zIndex: 10,
  };

  const menuItemStyle = {
    padding: '8px',
    cursor: 'pointer',
  };

  const confirmationDialogStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 15,
  };

  const dialogContentStyle = {
    backgroundColor: '#fff',
    padding: '16px',
    borderRadius: '8px',
    textAlign: 'center',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <MoreVertIcon onClick={toggleMenu}/>

      {isMenuOpen && (
        <div style={menuOptionsStyle} className="menu-options">
          <div style={menuItemStyle} onClick={() => handleMenuItemClick('View')}>View</div>
          <div style={menuItemStyle} onClick={() => handleMenuItemClick('Paid')}>Tag as Paid</div>
          <div style={menuItemStyle} onClick={() => handleMenuItemClick('Cancel')}>Cancel Billing</div>
        </div>
      )}

      {isConfirmationDialogOpen && (
        <div style={confirmationDialogStyle}>
          <div style={dialogContentStyle}>
            <p style={{ marginBottom: '16px' }}>Are you sure you want to update status as {selectedOption.toUpperCase().replace('CANCEL','CANCELLED')}?</p>
            <button onClick={handleConfirm} style={{ marginRight: '8px', background: '#5cb85c', color: '#fff', border: 'none', padding: '8px 16px', borderRadius: '4px', cursor: 'pointer' }}>Confirm</button>
            <button onClick={handleCancel} style={{ background: '#d9534f', color: '#fff', border: 'none', padding: '8px 16px', borderRadius: '4px', cursor: 'pointer' }}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default KebabMenuButton;
